import React from "react";
import {useAuth0} from "@auth0/auth0-react";


const LoggedInPage = () => {
    const {
        isLoading,
        isAuthenticated,
        error,
        user,
        loginWithRedirect,
        logout,
    } = useAuth0();

    if (isAuthenticated) {
        setTimeout(function(){
            // location.href = `${user.name}`
        }, 3)
    }

    if (isLoading) return <h1>is loading the authentication result</h1>;
    if (isAuthenticated && user) return <div>
        <h1>Welcome,  {user.name}!</h1>
        <button onClick={() => logout({returnTo: window.location.origin + "/logged-out"})}>
            Log Out
        </button>
    </div>
    return <div>
        <h1>Log-in Failed</h1>
        <p>due to {error && error.message}</p>
    </div>
};

export default LoggedInPage;